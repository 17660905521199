import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServicesModule } from "../services.module";
import { Child, School } from "src/app/shared/models";
import { CampaignTypeEnum } from "../../../shared/enums/campaign-type.enum";
import { FormManagerMatch } from "../../../features/screener/form-manager-page/form-manager-match.model";
import { EmailResponse, UserColumnVisibility, Triage, AdditionalFieldModel, AdditionalFieldValueModel } from "../../../features/screener/shared/models";
import { TestTypes } from 'src/app/features/screener/shared/enums/test-types.enum';
import { VaccineAdministrationResultModel } from 'src/app/features/screener/vaccine-administration-questionnaire/models/vaccine-administration-result.model';
import { CampaignInvitationListModel } from "src/app/features/screener/children-page/invitation-list-dialog/campaign-Invitation-list.model";
import { UpdateAdditionalFieldValueModel } from "src/app/features/screener/shared/components/view-school-dialog/edit-additional-field-dialog/update-additional-field-value.model";
import { MonthlyPlanningModel } from "src/app/features/screener/monthly-planning-page/monthly-planning.model";
import { ChildRestoreModel } from "src/app/shared/models/childRestore.model";
import { ClinicalVisionTestViewModel } from "src/app/features/screener/clinician/clinical-vision-test-view.model";
import { TestIdTypeModel } from "src/app/features/screener/children-page/right-panel/details-panel/test-id-type-model";
import { TenantKpiSetting } from "src/app/shared/models/tenant-kpi-setting.model";

@Injectable({
  providedIn: ServicesModule,
})
export class HttpScreenerService {
  constructor(private http: HttpClient) { }

  getSchools() {
    return this.http.get("api/schools");
  }

  getSchoolsByTenantId(tenantId: string) {
    return this.http.get("api/getSchoolsByTenantId/" + tenantId);
  }

  getChildren(tenantId: string, schoolId: string, filterOptions: any, isGetAllData: boolean, isGetOnlyChildIds: boolean = false) {
    let params = new HttpParams(); //Create new HttpParams

    for (let f of Object.keys(filterOptions)) {
      if (!Array.isArray(filterOptions[f])) {
        params = params.append(f, filterOptions[f]);
      }
      else if (filterOptions[f] && filterOptions[f].length > 0) {
        params = params.append(`${f}[]`, filterOptions[f]);
      }
    }

    if (filterOptions.testFilters) {
      for (let f of Object.keys(filterOptions.testFilters)) {
        filterOptions.testFilters[f].forEach((filterState) => {
          params = params.append(`${f}[]`, filterState.toString());
        })
      }
    }

    if (filterOptions.printedLetters) {
      filterOptions.printedLetters.forEach((printedLetter) => {
        params = params.append('printedLetters[]', printedLetter);
      })
    }

    if (filterOptions.notPrintedLetters) {
      filterOptions.notPrintedLetters.forEach((notPrintedLetter) => {
        params = params.append('notPrintedLetters[]', notPrintedLetter);
      })
    }

    if (filterOptions.vaccineIds) {
      params = params.delete('vaccineIds[]');
      filterOptions.vaccineIds.forEach((vaccineId) => {
        params = params.append('vaccineIds[]', vaccineId);
      })
    }

    if (filterOptions.consentTriageStates) {
      params = params.delete('consentTriageStates[]');
      filterOptions.consentTriageStates.forEach((consentTriageState) => {
        params = params.append('consentTriageStates[]', consentTriageState);
      })
    }

    if (filterOptions.activities) {
      params = params.delete('activities[]');
      filterOptions.activities.forEach((activity) => {
        params = params.append('activities[]', activity);
      })
    }
    if (tenantId) {
      return this.http.get("api/tenants/" + tenantId + "/schools/" + schoolId + "/children/" + isGetAllData + "/" + isGetOnlyChildIds, {
        params: params,
      });
    }
    return this.http.get("api/schools/" + schoolId + "/children/" + isGetAllData + "/" + isGetOnlyChildIds, {
      params: params,
    });
  }

  getVaccineAdministrationStatusFilters() {
    return this.http.get("api/GetVaccineAdministrationStatusFilters");
  }

  getCampaignsBySchoolId(schoolId: string) {
    return this.http.get("api/schools/" + schoolId + "/campaign");
  }

  getImmsCampaignsByTenantCode() {
    return this.http.get("/api/tenant-campaigns");
  }
// we have removed potential match, this method is not used.
  getPotetialMatchedConsentForms(childId: string, campaignId: string) {
    return this.http.get("api/potentialmatches/" + childId + "/" + campaignId);
  }

  includeChildInCampaign(
    childId: string,
    schoolId: string,
    campaignId: string
  ) {
    return this.http.post("api/screener/IncludeChildInCampaign", {
      ChildId: childId,
      SchoolId: schoolId,
      CampaignId: campaignId,
    });
  }

  includeChildrenInCampaign(
    childIds: string[],
    campaignId: string
  ) {
    return this.http.post("api/screener/IncludeChildrenInCampaign", {
      ChildIds: childIds,
      CampaignId: campaignId,
    });
  }

  removeChildrenFromCampaign(
    childIds: string[],
    campaignId: string
  ) {
    return this.http.post("api/screener/RemoveChildrenFromCampaign", {
      ChildIds: childIds,
      CampaignId: campaignId,
    });
  }

  getVaccineAdministrationDetails(childId: string, campaignId: string) {
    return this.http.get(
      "api/VaccineAdministrationDetails/" + childId + "/" + campaignId
    );
  }
  // we have removed potential match, this method is not used.
  saveSelectedChildConsentFormMatch(consentFormChildMatch: FormManagerMatch) {
    return this.http.post("api/SelectedMatch", consentFormChildMatch);
  }

  getConsentForm(childId: string, campaignId: string) {
    return this.http.get("api/getConsenForm/" + childId + "/" + campaignId);
  }

  uploadPaperConsent(formData: FormData) {
    return this.http.post("api/UploadPaperConsent", formData);
  }

  getNursesList() {
    return this.http.get("api/nursesList");
  }

  addTriageList(triageList: Triage[]) {
    return this.http.post("api/triage/addList", triageList);
  }

  getHistory(consentFormId: string) {
    return this.http.get("api/TriageHistory/" + consentFormId);
  }

  getChild(childId: string) {
    return this.http.get("api/Children/GetChild/" + childId);
  }

  getHNACampaignsBySchoolId(schoolId: string) {
    return this.http.get("api/schools/" + schoolId + "/HNACampaign");
  }

  getHNACampaignsByTenantCode() {
    return this.http.get("/api/hnatenantcampaigns");
  }

  getQuestionnaireSectionsByTenantCode() {
    return this.http.get("/api/questionnaire/getQuestionnaireSectionsByTenantCode");
  }

  getHNAParticipantsList(sessionId: string) {
    return this.http.get("api/participantsList/" + sessionId);
  }

  addChildBySubmission(submissionId: string, campaignType: number) {
    return this.http.post(`api/submission/addchild/${submissionId}/${campaignType}`, null);
  }

  checkDuplicateChildFromSubmission(submissionId: string, campaignType: number) {
    return this.http.post(`api/submission/checkDuplicateChildFromSubmission/${submissionId}/${campaignType}`, null);
  }

  moveSchoolAndMatch(submissionId: string, campaignType: number, childId: string, newSchoolId: string, isMoveSubmission: boolean) {
    return this.http.post(`api/submission/moveSchoolAndMatch/${submissionId}/${campaignType}/${childId}/${newSchoolId}/${isMoveSubmission}`, null);
  }

  matchSubmission(submissionId: string, campaignType: number, childId: string) {
    return this.http.post(`api/submission/matchSubmission/${submissionId}/${campaignType}/${childId}`, null);
  }

  getSubmissionMatchingHistoryByChildId(childId: string, campaignId: string) {
    return this.http.post(`api/submission/getSubmissionMatchingHistoryByChildId/${childId}/${campaignId}`, null);
  }

  getSubmissionMatchingHistoryBySubmissionId(submissionId: string) {
    return this.http.post(`api/submission/getSubmissionMatchingHistoryBySubmissionId/${submissionId}`, null);
  }

  getTemplates() {
    return this.http.get("api/Templates");
  }

  generateAndDownloadLetters(data: any) {
    return this.http.post("api/generateAndDownloadLetters", data);
  }

  downloadLettersForSchoolGrouping(data: any) {
    return this.http.post("api/generateAndDownloadLettersForSchoolGrouping", data);
  }

  downloadLettersForSchoolGroupingZip(data: any) {
    let options = {};
    options['responseType'] = 'arraybuffer';
    return this.http.post("api/generateAndDownloadLettersForSchoolGroupingZip", data, options);
  }

  downloadLettersForSchoolAndChildGroupingZip(data: any) {
    let options = {};
    options['responseType'] = 'arraybuffer';
    return this.http.post("api/generateAndDownloadLettersForSchoolAndChildGroupingZip", data, options);
  }

  getAllCampaignsBySchoolId(schoolId: string) {
    return this.http.get("api/schools/" + schoolId + "/campaigns/all");
  }

  getFormManagerChildren(schoolId: string, filter: any) {
    return this.http.get("api/form-manager/" + schoolId + "/children", {
      params: filter,
    });
  }

  getFormManagerSubmissions(schoolId: string, filter: any) {
    return this.http.get("api/form-manager/" + schoolId + "/submissions", {
      params: filter,
    });
  }

  getFormManagerChildDetails(
    childId: string,
    campaignId: string,
    schoolId: string,
    campaignType: number
  ) {
    return this.http.get("api/form-manager/child-details", {
      params: {
        childId: childId,
        campaignId: campaignId,
        schoolId: schoolId,
        campaignType: campaignType.toString(),
      },
    });
  }

  getFormManagerSubmissionDetails(submissionId: string, campaignType: number) {
    return this.http.get(
      "api/form-manager/submission-details/" + submissionId + "/" + campaignType
    );
  }

  getFormManagerSubmission(
    submissionId: string,
    campaignType: CampaignTypeEnum
  ) {
    return this.http.get(
      "api/form-manager/submission/" +
      submissionId +
      "/" +
      campaignType.toString()
    );
  }

  saveSelectedChildSubmissionMatch(match: FormManagerMatch) {
    return this.http.post("api/form-manager/submission-match", match);
  }

  deleteSubmission(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.delete(
      "api/form-manager/delete/submission/" +
      submissionId +
      "/" +
      campaignType.toString()
    );
  }

  restoreSubmission(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.post(
      "api/form-manager/restore/submission/" +
      submissionId +
      "/" +
      campaignType.toString(), ''
    );
  }

  addChild(child: any) {
    return this.http.post("api/Children", child);
  }

  deleteChild(childId: string) {
    return this.http.delete("api/delete/child/" + childId);
  }

  getSchoolById(schoolId: string) {
    return this.http.get("api/Schools/" + schoolId);
  }

  getReportHistory(childId: string) {
    return this.http.get("api/children/" + childId + "/report-history");
  }

  getReportAndMessageHistory(childId: string) {
    return this.http.get("api/children/" + childId + "/report-message-history");
  }

  getLastReportByChildId(childId: string) {
    return this.http.get("api/children/EmailPreview/" + childId);
  }

  getLastReportByChildIdAndTemplateId(childId: string, templateId: string) {
    return this.http.get(
      "api/children/EmailPreview/" + childId + "/" + templateId
    );
  }

  updateReport(emailResponse: EmailResponse) {
    return this.http.post(
      "api/children/EmailPreviewWithEditableFields",
      emailResponse
    );
  }

  sendEmailWithPdfWithEditableFields(emailResponse: EmailResponse) {
    return this.http.post(
      "api/SendEmailWithPdfWithEditableFields",
      emailResponse
    );
  }

  saveQueueWithEditableFields(emailResponse: EmailResponse) {
    return this.http.post("api/saveQueueWithEditableFields", emailResponse);
  }

  downloadPdfWithEditableFields(emailResponse: EmailResponse) {
    return this.http.post("api/DownloadPdfWithEditableFields", emailResponse);
  }

  deleteChildPdf(childPdfId: string) {
    return this.http.delete("api/delete/childpdf/" + childPdfId);
  }

  getReadOnlyVaccineAdministrationQuestionnaireByVaccineTestId(
    vaccineTestId: string
  ) {
    return this.http.get(
      "api/view/administration/questionnaire/vaccineTest/" + vaccineTestId
    );
  }

  rollbackVaccineAdministrationQuestionnaireByVaccineTestId(
    vaccineTestId: string, vaccineAdministrationId: string
  ) {
    return this.http.get("api/RollbackVaccineAdministration/" + vaccineTestId + "/" + vaccineAdministrationId);
  }

  getChildTestsList(childId: string, testType: TestTypes, pageSize: number, pageIndex: number) {
    return this.http.get("api/child/" + childId + "/tests/" + testType + "/" + pageSize + "/" + pageIndex);
  }

  getTest(testId: string, testType: TestTypes) {
    return this.http.get("api/test/" + testId + "/tests/" + testType);
  }

  deleteTest(testId: string, testType: TestTypes, submissionId: string = null) {
    let url: string = "api/delete/test/" + testId + "/" + testType;

    if (submissionId) {
      url += "/" + submissionId;
    }

    return this.http.delete(url);
  }

  unlockHnaSubmission(testId: string) {
    return this.http.post("api/unlock/test/" + testId, null);
  }

  restoreTest(testIdTypeModel: TestIdTypeModel) {
    return this.http.post("api/restore/test", testIdTypeModel);
  }

  getHearingTestDetails(hearingTestId: string) {
    return this.http.get("api/hearingTest/" + hearingTestId + "/details");
  }

  getClinicalHearingTestDetails(hearingTestId: string) {
    return this.http.get("api/ClinicalHearingTest/" + hearingTestId);
  }

  getGrowthBMILMS() {
    return this.http.get("api/GrowthTests/WebBMILMS");
  }

  getGrowthTestDetails(growthTestId: string) {
    return this.http.get("api/growthTest/" + growthTestId + "/details");
  }

  restoreDeletedChildren(childIds: string[]) {
    return this.http.post("api/children/restoreDeletedChildren", childIds);
  }

  replaceExistingChildren(child: ChildRestoreModel) {
    return this.http.post("api/children/replaceExistingChild", child);
  }

  getVaccineAdministrationQuestionnaireByVaccineId(vaccineId: string, childId: string, vaccineAdministrationId: string, consentFormId: string) {
    return this.http.get("api/getVaccineAdministrationQuestionnaire/" + vaccineId + '/' + childId + '/' + vaccineAdministrationId + '/' + consentFormId);
  }

  saveVaccineAdministrationResult(VaccineAdministrationResult: VaccineAdministrationResultModel) {
    return this.http.post("api/vaccineAdministrationResult/save", VaccineAdministrationResult);
  }

  addChildrenToSynProfile(childIds: string[]) {
    return this.http.post("api/children/syncprofile/add", childIds);
  }

  removeChildrenFromSyncProfile(childIds: string[]) {
    return this.http.post("api/children/syncprofile/remove", childIds);
  }

  unlinkAccount(userRelationshipId: string, childId: string) {
    return this.http.post("api/unlink/user/account/" + userRelationshipId + "/" + childId, null);
  }

  getUserColumnVisibilitySettings() {
    return this.http.get("api/User/GetColumnVisibilitySettingsForWeb");
  }

  saveUserColumnVisibilitySettings(columnVisibility: UserColumnVisibility) {
    return this.http.post("api/User/UpdateColumnVisibilitySettings", columnVisibility);
  }

  getAddressResultsFromPostCode(postCode: string) {
    return this.http.get("api/getAddressResultsFromPostCode/" + postCode);
  }

  getTenantCountryCode() {
    return this.http.get("api/tenant/countryCode");
  }

  getTenantOccupations() {
    return this.http.get("api/tenant/occcupations");
  }

  getTenantSettings() {
    return this.http.get("api/");
  }

  getLinkedUserAccountsAndSubmissionsByChildId(childId: string) {
    return this.http.get(`api/child/getLinkedAccountsAndSubmissions/${childId}`);
  }

  addChildrenToCampaignInvitationList(CampaignInvitationList: CampaignInvitationListModel) {
    return this.http.post(`api/campaign-invitation/${CampaignInvitationList.campaignId}/add`, CampaignInvitationList);
  }

  removeChildrenFromCampaignInvitationList(CampaignInvitationList: CampaignInvitationListModel) {
    return this.http.post(`api/campaign-invitation/${CampaignInvitationList.campaignId}/remove`, CampaignInvitationList);
  }

  getSchoolWithAdditionalFieldsData(schoolId: string) {
    return this.http.get(`api/school/${schoolId}/additional-fields`);
  }

  getTenantUsers() {
    return this.http.get('api/tenant/users');
  }

  getTenantKpiSettings() {
    return this.http.get<TenantKpiSetting>('api/tenant/tenantKpiSettings');
  }

  updateAdditionalFieldValue(updateModel: UpdateAdditionalFieldValueModel) {
    return this.http.post(`api/school/${updateModel.sourceId}/update/additional-field-value`, updateModel);
  }

  updateSchoolDetails(updateSchool: School) {
    return this.http.post(`api/school/${updateSchool.id}/details/update`, updateSchool);
  }

  getMonthlyPlanningData(monthlyPlanning: MonthlyPlanningModel) {
    return this.http.post('api/monthly/planning', monthlyPlanning);
  }

  getAllChildIdsBySchoolId(schoolId: string) {
    return this.http.get(`api/school/${schoolId}/all-childIds`);
  }

  getOrganizationsByIds(organizationIds: string[]) {
    return this.http.post(`api/OrganizationsByIds`, organizationIds);
  }

  getAdditonalFieldsBySchoolIds(schoolIds: string[]) {
    return this.http.post('api/additionalfields/schools', schoolIds);
  }

  clearAdditionalFieldValue(additionalFieldId: string) {
    return this.http.get(`api/delete/additional-field-value/${additionalFieldId}`);
  }

  getLastAdministeredTestDate(vaccineAdministrationId: string, vaccineId: string) {
    return this.http.get(`api/GetLastAdministeredTestDateAndSubstitutes/${vaccineAdministrationId}/${vaccineId}`);
  }

  getCohortsOfSchool(schoolId: string) {
    return this.http.get(`api/children/cohorts/${schoolId}`);
  }

  getSharedDocumentsBySchoolIds(schoolIds: string, pageSize: number, pageIndex: number) {
    return this.http.post('api/sharedDocuments/schools' + "/" + pageSize + "/" + pageIndex, { schoolIds });
  }

  downloadSharedDocumentUpload(sharedDocumentUploadId: string) {
    return this.http.get(`api/sharedDocuments/${sharedDocumentUploadId}/download`);
  }

  addUpdateSharedDocument(formData: FormData) {
    return this.http.post('api/addUpdateSharedDocument', formData);
  }

  deleteSharedDocument(sharedDocumentId: string) {
    return this.http.get(`api/sharedDocuments/${sharedDocumentId}/delete`);
  }


  deleteSharedDocumentSource(sharedDocumentId: string, sourceId: string, allDelete: boolean) {
    return this.http.get(`api/sharedDocuments/${sharedDocumentId}/${sourceId}/${allDelete}/deletesource`);
  }

  saveDecodeResults(qrCode: string, batchNumber: string, expirationDate: Date, isSuccessful: boolean = true, details: string = null) {
    return this.http.post("api/qrCode/save",
      {
        QrCode: qrCode,
        BatchNumber: batchNumber,
        ExpirationDate: expirationDate,
        IsSuccessful: isSuccessful,
        Details: details
      });
  }

  getChildrenWithTestDetailsByChildIds(childIds: string[]) {
    return this.http.post('api/getChildrenWithTestDetailsByChildIds', childIds);
  }

  mergeChildren(masterChildId: string, childId: string) {
    return this.http.get(`api/mergeChildren/${masterChildId}/${childId}`);
  }

  getChildrenByChildIds(childIds: string[]) {
    return this.http.post('api/children/get-children', childIds);
  }

  generateLettersAndAddToQueue(data: any) {
    return this.http.post("api/generateLettersAndAddToQueue", data);
  }

  getClinics() {
    return this.http.get("api/clinics");
  }

  getBookingList(filterOptions: any, isGetAllData: boolean) {
    let params = new HttpParams(); //Create new HttpParams
    for (let f of Object.keys(filterOptions)) {
      if (!Array.isArray(filterOptions[f])) {
        params = params.append(f, filterOptions[f]);
      }
      else if (filterOptions[f] && filterOptions[f].length > 0) {
        params = params.append(`${f}[]`, filterOptions[f]);
      }
    }

    return this.http.get("api/bookings/" + isGetAllData, {
      params: params,
    });

  }

  deleteBooking(consentFormId: string) {
    return this.http.delete("api/delete/booking/" + consentFormId);
  }

  checkChildRecordsForSoftDeleteAndUpdateChild(childId: string) {
    return this.http.get(`api/checkChildRecordsForSoftDeleteAndUpdateChild/${childId}`);
  }

  searchChildByReferralCode(referralCode: string) {
    return this.http.get(`api/clinician/searchChildByReferralCode/${referralCode}`);
  }

  getVisionTestIncludingChild(referralCode: string) {
    return this.http.get(`api/clinician/getVisionTestIncludingChild/${referralCode}`);
  }

  getClinicalVisionTest(visionTestId: string) {
    return this.http.get(`api/clinician/getClinicalVisionTest/${visionTestId}`);
  }

  getHearingTestIncludingChild(referralCode: string) {
    return this.http.get(`api/clinician/getHearingTestIncludingChild/${referralCode}`);
  }

  getClinicalHearingTest(hearingTestId: string) {
    return this.http.get(`api/clinician/getClinicalHearingTest/${hearingTestId}`);
  }

  saveClinicalVisionTest(clinicalVisionTest: ClinicalVisionTestViewModel) {
    return this.http.post("api/clinician/saveClinicalVisionTest", clinicalVisionTest);
  }

  getUserAssignedTenants() {
    return this.http.get(`api/User/GetUserAssignedTenants`);
  }

  getSchoolDataForAdmin(schoolIds: string[]) {
    return this.http.post("api/schools/getSchoolDataForAdmin", schoolIds);
  }

  getUserListForAdmin(organizationIds: string[]) {
    return this.http.post("api/schools/getUserListForAdmin", organizationIds);
  }

  getCohortsForImport() {
    return this.http.get("api/schools/getCohortsForImport");
  }

  importUpdateChildrenWithCohort(formData: FormData) {
    return this.http.post("api/schools/importUpdateChildrenWithCohort", formData);
  }

  downloadChildrenImportForMultipleSchoolsSample() {
    return this.http.get("api/schools/samples/children-csv-import-multiple-schools", {
      responseType: 'blob',
      observe: 'response'
    });
  }

  getSessionBatchCodes(schoolId: string) {
    return this.http.get("api/schools/" + schoolId + "/getSessionBatchCodes");
  }
}
